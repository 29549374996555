import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    authentificationCard: {
        width: '100%',
        maxWidth: '460px',
        padding: '22px',
        boxShadow: `4px 4px 0px 0px ${theme.palette.fg.black}`,
        borderRadius: '19px',
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        [theme.breakpoints.up('sm')]: {
            padding: '45px 57px 54px'
        }
    },
    authentificationCardContent: {
        width: '100%',
        padding: '0px !important'
    },
    formContainer: {
        width: '100%'
    },
    titleBox: {
        paddingBottom: '11px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '33px'
        }
    },
    formTitle: {
        color: theme.palette.fg.black,
        fontFamily: 'GreedWide-Bold',
        lineHeight: '40px',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            fontSize: 20
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '29px'
        }
    },
    inputsContainer: {
        width: '100%',
        paddingBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px'
    },
    formControl: {
        width: '100%'
    },
    inputLabel: {
        paddingBottom: '6px',
        paddingLeft: '9px',
        color: theme.palette.fg.black,
        lineHeight: '17px',
        [theme.breakpoints.down('lg')]: {
            fontSize: 14
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18
        }
    },
    inputRoot: {
        width: '100%',
        height: '42px',
        padding: '0px 23px',
        borderRadius: '78px',
        border: `1px solid ${theme.palette.fg.black}`,
        [theme.breakpoints.up('sm')]: {
            height: '50px'
        }
    },
    separationText: {
        paddingTop: '14px',
        paddingBottom: '14px',
        color: theme.palette.fg.greyMedium,
        fontSize: '13px',
        lineHeight: '17px',
        textAlign: 'center'
    },
    checkboxContainer: {
        paddingBottom: '14px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '6px'
    },
    checkbox: {
        marginLeft: '-9px'
    },
    checkboxLabel: {
        color: theme.palette.fg.greySlate,
        fontSize: '14px',
        textAlign: 'justify'
    },
    button: {
        width: '100%',
        padding: '10px 20px',
        textTransform: 'none'
    },
    forgotPasswordLinkContainer: {
        width: '100%',
        paddingTop: '10px',
        display: 'flex',
        justifyContent: 'center'
    },
    forgotPasswordLink: {
        display: 'block'
    },
    forgotPasswordLinkText: {
        color: theme.palette.fg.greyMedium,
        fontSize: '14px'
    },
    facebookIconContainer: {
        width: '33px',
        height: '33px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg.silver,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    reverseAuthLinkContainer: {
        width: '100%',
        paddingTop: '12px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '12x'
        }
    },
    reverseAuthLink: {
        display: 'block',
        cursor: 'pointer'
    },
    reverseAuthLinkText: {
        color: theme.palette.fg.greyMedium,
        lineHeight: '17px',
        // fontWeight: 'bold',
        fontSize: 14,
        textDecoration: 'underline'
    }
}))
