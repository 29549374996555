import React, { useRef, useState } from 'react'
import { useStyles } from '../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'
import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterArrows from '../shared/QuickFilterArrows/QuickFilterArrows'
import QuickFilterTag from '../shared/QuickFilterTag/QuickFilterTag'

const CompanyQuickFilters = (props) => {
    const classes = useStyles()
    const { filters, setFilters } = props
    const quickFilterCompanyCollection = CollectionHandler.Get('quickFilterCompanyCollection')
    const quickFiltersRef = useRef(null)
    const [isHoverText, setIsHoverText] = useState(null)

    return (
        <Box className={classes.quickFilterContainer}>
            <Box className={classes.quickFilters} ref={quickFiltersRef}>
                {quickFilterCompanyCollection.map(quickFilterOption => {
                    return (
                        <QuickFilterTag
                            key={quickFilterOption.value}
                            filters={filters}
                            setFilters={setFilters}
                            quickFilterOption={quickFilterOption}
                            quickFilterSelection={filters.quickFilter}
                            page='companies'
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

export default CompanyQuickFilters
