/* eslint-disable react/prop-types */
import React from 'react'
import Map from 'wrappers/MapProvider/components/Map/Map'
import CardContainer from 'containers/CardContainer/CardContainer'
import RecentArticles from 'containers/RecentArticles/RecentArticles'
import AdBanner from 'components/AdBanner/AdBanner'
import NewOfferButton from 'components/NewOfferButton/NewOfferButton'
import ArticleBanner from '../ArticleBanner/ArticleBanner'
import SEOBlock from '../SEOBlock/SEOBlock'
import {
    JOB_INDEX_FILTER_BUTTON,
    JOB_INDEX_MOBILE_STICKY_BUTTON,
    JOB_INDEX_ALERT_AD_BUTTON,
    JOB_INDEX_SEE_MORE_BUTTON
} from 'assets/AlertsHelper'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'
import css from './itemindex.scss'
import css_responsive from './itemindex_responsive.scss'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import CTAAlert from 'components/CTAAlert/CTAAlert'
import ShowMoreButton from 'components/CTAAlert/ShowMoreButton/ShowMoreButton'

const ItemIndex = (props) => {
    if (props.items.length === 0) return null

    const chunckedItems = []
    for (let i = 0, j = props.items.length; i < j; i += 9) {
        chunckedItems.push(props.items.slice(i, i + 9))
    }

    const renderCardContainers = (itemList, pageToShow) => {
        return itemList.map((items, i) => (
            <Box
                className={`page-card-to-${i < pageToShow ? 'show' : 'show'}`}
                key={`cardcontainer_${i}`}
                ref={i === itemList.length - 1 ? props.jumpingRef : null}
                id={`cardContainer_${i}`}
            >
                {i !== 0 && (
                    <Box id={`listAds-desktop-${i}`} mb='21px'>
                        {listAds(i)}
                    </Box>
                )}
                <CardContainer
                    key={`cardcontainer_${i}`}
                    type={props.type}
                    displayShow={props.displayShow}
                    cards={items}
                    userAddress={props.userAddress}
                    userType={props.user?.type}
                    pageName={props.pageName}
                    mapProviderProps={props.mapProviderProps}
                />
            </Box>
        ))
    }

    const halfPageAds = () => {
        const locale = RouteMap.GetLocaleFromUrl()
        const localizedAds = props.ads.filter((ad) => ad.langage === locale)
        const places = []

        localizedAds.forEach((ad) => {
            ad.places.forEach((place) => {
                if (place.includes('Index_Half page')) {
                    places.push(place)
                }
            })
        })

        if (places.length === 0 && props.type !== 'article') {
            return (
                <Box pl={2}>
                </Box>
            )
        }

        places.sort()

        return places.map((place, index) => (
            <React.Fragment key={`ad-fragment-${index}`}>
                <AdBanner
                    key={`ad-banner-${index}`}
                    ads={localizedAds}
                    place={place}
                    device='desktop'
                    width={300}
                    noMarginBottom
                />
                {index === 0 && (
                    <Box style={{ width: '100%' }}>
                        <RecentArticles key={`recent-articles-${index}`} category={props.type} />
                    </Box>
                )}
            </React.Fragment>
        ))
    }

    const listAdForJobType = () => {
        switch (props.type) {
        case 'first_job':
            return 'Firstjobs_Index_Leaderboard_'
        case 'internship':
            return 'Internships_Index_Leaderboard_'
        case 'student_job':
            return 'Studentjobs_Index_Leaderboard_'
        case 'kot':
            return 'Kots_Index_Leaderboard_'
        default:
            return ''
        }
    }

    const leaderBoardAdPlace = () => {
        switch (props.type) {
        case 'first_job':
            return 'Firstjobs_Index_'
        case 'internship':
            return 'Internships_Index_'
        case 'student_job':
            return 'Studentjobs_Index_'
        case 'kot':
            return 'Kots_Index_'
        default:
        }
    }

    const listAds = (index) => {
        const place = 'Blog_Index_Leaderboard_' + index
        const isIndexEven = index % 2 === 0
        const isJobType = JOB_TYPES_LIST.includes(props.type)

        if (
            props.type !== 'article' &&
            ['Mobile', 'Leaderboard'].includes(place.split('_')[2])
        ) {
            return isJobType && !isIndexEven ? (
                <ArticleBanner offset={index} category={props.type} />
            ) : (
                isJobType &&
                (props.user?.type === 'student' || !props.user) && (
                    <CTAAlert
                        type={props.type}
                        user={props.user}
                        componentType='banner'
                        origin={JOB_INDEX_ALERT_AD_BUTTON}
                    />
                )
            )
        }

        index = index + 2
        return props.type === 'article' ? (
            <AdBanner
                ads={props.ads}
                key={'Blog_Index_Leaderboard_'}
                place={place}
                device='desktop'
                width={728}
            />
        ) : (
            <AdBanner
                ads={props.ads}
                key={listAdForJobType()}
                place={listAdForJobType() + index}
                device='desktop'
                width={728}
            />
        )
    }

    const showMoreClick = () => {
        props.filterItems(false)
    }

    const pageToShow = window.innerWidth <= 1140 ? 2 : 3

    return (
        <Box className='item-index-container'>
            <Box className={`cards-and-list ${props.type}-type`}>
                {props.loading && (
                    <div className='loading-cirular'>
                        <CircularProgress
                            color='primary'
                            style={{ width: '80px', height: '80px' }}
                        />
                    </div>
                )}
                {!props.loading && props.items.length > 0 && (
                    <>
                        {Array.isArray(props.ads) && props.ads.length > 0 && (
                            <>
                                <AdBanner
                                    ads={props.ads}
                                    place={leaderBoardAdPlace() + 'Leaderboard_1'}
                                    device='desktop'
                                    width={728}
                                />
                                <AdBanner
                                    ads={props.ads}
                                    place={leaderBoardAdPlace() + 'Mobile_1'}
                                    device='mobile'
                                    width={320}
                                />
                            </>
                        )}
                        <Hidden smDown>
                            <Box className='buttonsMobileContainer'>
                                <NewOfferButton userType={props.user?.type} type={props.type} />
                                <CTAAlert
                                    type={props.type}
                                    user={props.user}
                                    componentType='default'
                                    origin={JOB_INDEX_FILTER_BUTTON}
                                />
                            </Box>
                        </Hidden>
                        <Box>
                            {renderCardContainers(chunckedItems, pageToShow)}
                            <ShowMoreButton
                                type={props.type}
                                user={props.user}
                                origin={JOB_INDEX_SEE_MORE_BUTTON}
                                loadingMore={props.loadingMore}
                                loadMoreStatus={props.loadMoreStatus}
                                showMoreAction={showMoreClick}
                            />
                        </Box>
                    </>
                )}
                {props.loadingMore && (
                    <Box display='flex' justifyContent='center' fullWidth my={4}>
                        <CircularProgress color='primary' style={{ width: '80px', height: '80px' }} />
                    </Box>
                )}
                {!props.loading && props.items.length === 0 && (
                    <Box mt={4} mb={8}>
                        <Typography variant='body2'>
                            <FormattedMessage id='item_index.no_found_offer' />
                        </Typography>
                    </Box>
                )}
                <SEOBlock
                    type={props.type}
                    selectedLocationTag={props.selectedLocationTag}
                />
            </Box>
            {props.type !== 'company' && <Box className='item-index-right-panel'>
                {((props.type === 'kot' && props.isCheckMap) || props.type !== 'kot') && (
                    <Box className='half-page-ads'>
                        {['first_job', 'internship', 'student_job', 'kot', 'article'].includes(props.type) && (
                            <>
                                <Box className='buttons-container'>
                                    <NewOfferButton userType={props.user?.type} type={props.type} />
                                    <CTAAlert
                                        type={props.type}
                                        user={props.user}
                                        componentType='default'
                                        origin={JOB_INDEX_FILTER_BUTTON}
                                    />
                                </Box>
                                {halfPageAds()}

                            </>
                        )}
                    </Box>
                )}
                {props.itemsGeocoding && props.type === 'kot' && !props.isCheckMap && (
                    <Box className={'map-container map-shown'}>
                        <Map
                            type={props.type}
                            itemsGeocoding={props.itemsGeocoding}
                            ref={props.mapRef}
                            mapProviderProps={props.mapProviderProps}
                        />
                    </Box>
                )}
            </Box>}
            {JOB_TYPES_LIST.includes(props.type) &&
                (props.user?.type === 'student' || !props.user) && (
                <Hidden smUp>
                    <CTAAlert
                        type={props.type}
                        user={props.user}
                        componentType='stickyButton'
                        origin={JOB_INDEX_MOBILE_STICKY_BUTTON}
                    />
                </Hidden>
            )}
        </Box>
    )
}

export default ItemIndex
