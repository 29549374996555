/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactQuill from 'react-quill'
import TagManager from 'react-gtm-module'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import ExperienceHelper from 'assets/ExperienceHelper'
import isEmpty from 'lodash.isempty'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './ApplyPopupComponent_style'

import CandidacyAPI from 'api/CandidacyAPI'
import DateHandler from 'assets/DateHandler'
import ExperienceProfile from 'pages/user/Profile/StudentProfile/Components/ExperienceProfile/ExperienceProfile'
import Button from 'shared/components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import InfoProfile from 'pages/user/Profile/StudentProfile/Components/InfosProfile/InfoProfile'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import LanguageProfile from 'pages/user/Profile/StudentProfile/Components/LanguageProfile/LanguageProfile'
import LoginRegisterPage from 'pages/LoginRegister/LoginRegister/LoginRegisterPage'
import RouteMap from 'assets/RouteMap.js'
import URLParamsHandler from 'assets/URLParamsHandler'
import { isScreenSizeMobile } from 'assets/WindowHelper'
import UploadDocument from 'containers/UploadDocument/UploadDocument'
import UserAPI from 'api/UserAPI'
import JobCardV2 from 'cards/JobCard/JobCardV2'
import { JOB_APPLICATION } from 'assets/AuthentificationHelper'
import Bugsnag from '@bugsnag/js'

const CV_AGE_WARNING = 6 // Months

const ApplyPopupComponent = (props) => {
    const classes = useStyles()
    const [state, setStateBis] = useState({})
    const [student, setStudent] = useState({})
    const [imageArray, setImageArray] = useState([])
    const [documentIdToSend, setDocumentIdToSend] = useState(null)
    const [imageAvatar] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [studentFormation, setStudentFormation] = useState([])
    const [experienceIsUpdated, setExperienceIsUpdated] = useState(false)
    const [hasExperience, setHasExperience] = useState(false)
    const [studentLanguage, setStudentLanguage] = useState([])
    const [answers, setAnswers] = useState({})
    const [motivationLetterContent, setMotivationLetterContent] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoaded, setLoaded] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [steps, setSteps] = useState([])
    const [titles, setTitles] = useState([])
    const [showApplyButtonOnStep, setShowApplyButtonOnStep] = useState()
    const [isLogin, setIsLogin] = useState(props.user !== null)
    const [commonEventProps] = useState({
        job_type: props.jobInfo.type,
        job_slug: props.jobInfo.slug,
        application_type: props.jobInfo.application_type,
        is_favourite: props.jobInfo.is_favourite,
        already_chatted: props.jobInfo.chatCreated
    })
    const [isGDPRChecked, setGDPRChecked] = useState(false)
    const [isRedirectingToPartner, setIsRedirectingToPartner] = useState(false)

    const skipCVStep = isScreenSizeMobile() && props.jobInfo.type === 'student_job'
    const titleInfo = [
        props.jobInfo.application_url ? '' : 'apply_popup.titles.infos.info_1',
        'apply_popup.titles.infos.info_2',
        'apply_popup.titles.infos.info_3',
        ''
    ]

    useEffect(
        () => {
            const tmpSteps = ['apply_popup.titles.profile_summary']
            const tmpTitles = ['apply_popup.titles.profile_summary', 'apply_popup.titles.cv', 'apply_popup.titles.job_questions', 'apply_popup.titles.apply_message']
            let tmpShowApplyButtonStep = 0
            if (props.jobInfo.application_type !== 'url') {
                if (!skipCVStep) {
                    tmpShowApplyButtonStep = 1
                    tmpSteps.push('apply_popup.titles.cv')
                }
                if (props.jobInfo.type === 'student_job') {
                    if (props.jobInfo.job_questions.length > 0) {
                        tmpShowApplyButtonStep = 2
                        tmpSteps.push('apply_popup.titles.job_questions')
                    } else {
                        tmpTitles.push('null')
                    }
                }
                if (props.jobInfo.motivation_letter) {
                    tmpShowApplyButtonStep = 3
                    tmpSteps.push('apply_popup.titles.apply_message')
                }
            }
            setShowApplyButtonOnStep(tmpShowApplyButtonStep)
            setSteps(tmpSteps)
            setTitles(tmpTitles)
            if (props.user) {
                setIsLogin(true)
            }
        }, []
    )

    useEffect(
        () => {
            if (isLogin) {
                fetchUserInfos()
            }
        }, [isLogin === true]
    )

    useEffect(
        () => {
            if (isLoaded) {
                initializeProfileInfo()
            }
        }, [isLoaded === true]
    )
    useEffect(() => {
        if (isSelectedDocumentPresent()) {
            const selectedCv = getSelectedDocument()

            if (DateHandler.isDateOlderThan(selectedCv.updated_at, CV_AGE_WARNING)) {
                setErrorMessage(<FormattedMessage id='landing_upload.doc_old' />)
            } else {
                setErrorMessage('')
            }
        }
    }, [documentIdToSend])

    useEffect(() => {
        if (steps.length === 0) return

        const isFunnelCondensed = props.jobInfo.type.match(/^(first_job|internship)$/g)
        const totalSteps = isFunnelCondensed ? 1 : steps.length
        const customEventProps = {
            ...commonEventProps,
            total_steps: totalSteps
        }
        switch (activeStep) {
        case 0:
            customEventProps.current_step_name = isFunnelCondensed ? 'Profile + CV' : 'Profile'
            if (isLogin) {
                AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Started', custom_props: customEventProps })
                sendEventApplyStarted()
            }
            break
        case 1:
            customEventProps.current_step_name = 'CV'
            if (isLogin) AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Ongoing', custom_props: customEventProps })
            break
        case 2:
            customEventProps.current_step_name = 'Questions'
            if (isLogin) AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Ongoing', custom_props: customEventProps })
            break
        case 3:
            customEventProps.current_step_name = 'Motivation letter'
            if (isLogin) AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Ongoing', custom_props: customEventProps })
            break
        }
    }, [activeStep, steps])

    const blankLanguage = {
        name: '',
        skill_weight: '',
        deleted: false

    }

    const getJobCompanyInfo = () => {
        return {
            id: props.jobInfo.company_id,
            name: props.jobInfo.company_name,
            sector: props.jobInfo.sector,
            size: props.jobInfo.company_size
        }
    }

    const sendEventApplyStarted = () => {
        switch (props.jobInfo.type) {
        case 'student_job':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Started - Student Job',
                AnalyticsHelper.constructStudentJobAnalyticsAttributes(props.user, props.jobInfo, getJobCompanyInfo(), skipCVStep)
            )
            break
        case 'internship':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Started - Internship',
                AnalyticsHelper.constructFirstInternJobAnalyticsAttributes(props.user, props.jobInfo, getJobCompanyInfo())
            )
            break
        case 'first_job':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Started - First Job',
                AnalyticsHelper.constructFirstInternJobAnalyticsAttributes(props.user, props.jobInfo, getJobCompanyInfo())
            )
            break
        }
    }

    const sendEventApplyCompleted = () => {
        switch (props.jobInfo.type) {
        case 'internship':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Completed - Internship',
                AnalyticsHelper.constructFirstInternJobAnalyticsAttributes(
                    AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                    props.jobInfo,
                    getJobCompanyInfo())
            )
            break
        case 'first_job':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Completed - First Job',
                AnalyticsHelper.constructFirstInternJobAnalyticsAttributes(
                    AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                    props.jobInfo,
                    getJobCompanyInfo()
                )
            )
            break
        case 'student_job':
            AnalyticsHelper.sendGTMEvent(
                'Job Apply Completed - Student Job',
                AnalyticsHelper.constructStudentJobAnalyticsAttributes(
                    AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                    props.jobInfo,
                    getJobCompanyInfo(),
                    skipCVStep
                )
            )
            break
        }
    }

    const redirectionApply = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'apply' })
    }

    const redirectionRegisterApply = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'apply', authentification: 'register' })
    }

    const initializeProfileInfo = () => {
        state.userLanguages.other_languages.map(
            (lng) => {
                studentLanguage.push({
                    name: lng.name.toUpperCase(),
                    skill_weight: lng.skill_weight,
                    deleted: false
                })
            }
        )
        setStudentLanguage([...studentLanguage])
    }

    const fetchUserInfos = () => {
        UserAPI.GetUserInfo().then(response => {
            const dataUser = {
                updateProfileStatus: false,
                profile: response.data.user.profile,
                address: response.data.user.address,
                userLanguages: response.data.user.languages,
                documents: response.data.user.documents,
                studies: response.data.user.studies
            }

            if (!isEmpty(response.data.user.experiences)) setHasExperience(true)

            const initializedExperiences = ExperienceHelper.InitializeExperiences(response.data.user.experiences)
            setStudentFormation(initializedExperiences)

            const state = dataUser
            setStateBis(state)

            setStudent({
                first_name: response.data.user.profile.first_name,
                last_name: response.data.user.profile.last_name,
                gender: response.data.user.profile.gender || 0,
                phoneNumber: response.data.user.profile.phone_number || '',
                street: response.data.user.address ? response.data.user.address.street : '',
                city: response.data.user.address ? response.data.user.address.city : '',
                country: response.data.user.address ? response.data.user.address.country : '',
                zip: response.data.user.address ? response.data.user.address.zip : '',
                intro: response.data.user.profile.intro || '',
                drivingLicence: response.data.user.profile.driving_licence || false,
                car: response.data.user.profile.car || false,
                bike: response.data.user.profile.bike || false,
                FR: response.data.user.languages.fr || null,
                EN: response.data.user.languages.en || null,
                NL: response.data.user.languages.nl || null,
                documents: response.data.user.documents
            })

            if (response.data.user.documents) {
                setImageArray(response.data.user.documents)
            }
            // setImageAvatar(response.data.user.)
            setSelectedDate(response.data.user.profile.birthdate ? new Date(response.data.user.profile.birthdate) : null)
            // setLoaded(true)
        }).then(() => {
            setLoaded(true)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getProfileData = () => {
        const formationArray = ExperienceHelper.PrepareExperiencesDataToSave(studentFormation)

        const newLang = []
        studentLanguage.forEach(
            (langue, key) => {
                if (langue.name === '' && langue.skill_weight !== '') {
                    studentLanguage[key].deleted = true
                }
                if (!langue.deleted) {
                    newLang.push(langue)
                }
            }
        )
        state.userLanguages.en = student.EN
        state.userLanguages.fr = student.FR
        state.userLanguages.nl = student.NL
        return {
            first_name: student.first_name,
            last_name: student.last_name,
            gender: student.gender,
            phone_number: student.phoneNumber,
            picture_id: imageAvatar.length > 0 ? imageAvatar[0].id : null,
            street: student.street,
            city: student.city,
            country: student.country,
            zip: student.zip,
            birthdate: DateHandler.ToBasicFormat(selectedDate),
            car: student.car,
            bike: student.bike,
            driving_licence: student.drivingLicence,
            experiences: formationArray,
            EN: student.EN,
            FR: student.FR,
            NL: student.NL,
            document: student.documents ? student.documents : [],
            other_languages: newLang,
            ignore_languages: props.jobInfo.type !== 'student_job'
        }
    }

    const getCandidacyData = () => {
        const answersIds = Object.keys(answers).map((answer) => {
            return Object.keys(answers[answer]).map((id) => {
                return id
            })
        }).flat()

        return {
            id: props.jobInfo.id,
            type: props.jobInfo.type,
            student_documents: documentIdToSend,
            motivation_letter: motivationLetterContent,
            answers: answersIds
        }
    }

    const triggerAnals = () => {
        const lastFormation = Object.assign({}, studentFormation.reduce((acc, exp) => {
            return (acc = acc.end_year > exp.end_year ? acc : exp)
        }))
        const analObject = AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
            props.user,
            student,
            lastFormation,
            selectedDate,
            'ApplyPopupComponent'
        )
        experienceIsUpdated && ExperienceHelper.SendExperienceUpdatedGTMEvent(hasExperience, analObject)
        AnalyticsHelper.sendGTMEvent('Account Updated', analObject)
    }

    const updateProfileWithApplicationUrl = () => {
        setLoaded(false)
        if (!(student.updated || studentFormation.updated || studentLanguage.updated)) {
            createCandidacyWithApplicationUrl()
        } else {
            UserAPI.UserEditMyProfile(getProfileData()).then((response) => {
                createCandidacyWithApplicationUrl()
                triggerAnals()
            })
        }
    }

    const createCandidacyWithApplicationUrl = () => {
        UserAPI.SendCandidacy(getCandidacyData()).then((response) => {
            if (response.data.ats_endpoint) {
                sendCandidacyEventAndClosePopup()
                window.location.href = response.data.ats_endpoint
            } else if (response.data.candidacy_id) {
                sendCandidacyEventAndClosePopup()
                window.location.href = props.jobInfo.application_url
            }
        })
    }

    const sendCandidacyEventAndClosePopup = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'apply-success',
                language: RouteMap.GetLocaleFromUrl(),
                job_type: props.jobInfo.type
            }
        })
        AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Completed', custom_props: commonEventProps })
        sendEventApplyCompleted()
        props.popupExit()
    }

    const shouldProfileBeUpdated = () => {
        return student.updated || studentFormation.updated || studentLanguage.updated
    }

    const handleProfileUpdateAndSendApplication = () => {
        if (shouldProfileBeUpdated()) {
            updateProfileInfo().then(() => {
                sendApplication()
            })
        } else {
            sendApplication()
        }
    }

    const updateProfileInfo = () => {
        setLoaded(false)
        return UserAPI.UserEditMyProfile(getProfileData()).then(() => {
            triggerAnals()
            setLoaded(true)
        })
    }

    const sendApplication = () => {
        setLoaded(false)
        UserAPI.SendCandidacy(getCandidacyData())
            .then((response) => {
                if (response.data.candidacy_id) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'apply-success',
                            language: RouteMap.GetLocaleFromUrl(),
                            job_type: props.jobInfo.type
                        }
                    })
                    window.location.href = `${RouteMap.Page('/')}users/congratulation?jobtype=${props.jobInfo.type}&job_id=${props.jobInfo.id}`
                    AnalyticsHelper.sendAnalyticsEvent('track', { user_id: props.user.id, event_name: 'Candidacy Completed', custom_props: commonEventProps })
                    sendEventApplyCompleted()
                }
            }).catch((error) => {
                setLoaded(true)
                setErrorMessage('Something went wrong')
                Bugsnag.notify(error)
            })
    }

    const getSelectedDocument = () => {
        return imageArray.find(doc => doc.id === parseInt(documentIdToSend))
    }

    const isSelectedDocumentPresent = () => {
        return !!getSelectedDocument()
    }

    const validateLanguageForm = () => {
        const allLanguagesPresent = student.FR && student.NL && student.EN
        if (allLanguagesPresent) return true

        return false
    }

    const handleStudentJobApplication = (event) => {
        event.preventDefault()
        var isNextStep = true
        var nextPageCounter = 1

        switch (activeStep) {
        case 0:
            if (props.jobInfo.company.ats_integration && !isGDPRChecked) {
                isNextStep = false
                setErrorMessage(<FormattedMessage id="apply_popup.gdpr_checkbox_error" />)
            } else {
                if (skipCVStep) {
                    if (props.jobInfo.job_questions.length > 0) {
                        nextPageCounter = 2
                    } else if (props.jobInfo.motivation_letter) {
                        nextPageCounter = 3
                    } else {
                        nextPageCounter = 0
                    }
                }

                if (!validateLanguageForm()) return

                if (props.jobInfo.application_type === 'url') {
                    updateProfileWithApplicationUrl()
                } else if (activeStep === nextPageCounter) {
                    handleProfileUpdateAndSendApplication()
                } else {
                    if (shouldProfileBeUpdated()) {
                        updateProfileInfo()
                    }
                }

                executeNextStep(
                    isNextStep,
                    nextPageCounter,
                    () => AnalyticsHelper.sendGTMEvent(
                        'Profile Summary Filled - Student Job',
                        AnalyticsHelper.constructStudentJobAnalyticsAttributes(
                            AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                            props.jobInfo,
                            getJobCompanyInfo(),
                            skipCVStep
                        )
                    )
                )
            }
            break
        case 1:
            nextPageCounter = 2
            if (!documentIdToSend || !isSelectedDocumentPresent()) {
                isNextStep = false
                setErrorMessage(<FormattedMessage id="apply_popup.errors.missing_document" />)
            } else {
                CandidacyAPI.CheckAndRetrieveStudentDocuments({
                    document_id: documentIdToSend
                }).then((response) => {
                    if (props.jobInfo.type === 'student_job' && props.jobInfo.job_questions.length > 0) {
                        nextPageCounter = 1
                    } else if (!props.jobInfo.motivation_letter) {
                        nextPageCounter = 0
                        sendApplication()
                    }
                    executeNextStep(
                        isNextStep,
                        nextPageCounter,
                        () => AnalyticsHelper.sendGTMEvent(
                            'CV Uploaded - Student Job',
                            AnalyticsHelper.constructStudentJobAnalyticsAttributes(
                                AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                                props.jobInfo,
                                getJobCompanyInfo(),
                                skipCVStep
                            )
                        )
                    )
                }).catch((error) => {
                    isNextStep = false
                    setErrorMessage(error.response.data.error)
                    setImageArray(error.response.data.student_documents)
                })
            }

            break
        case 2:
            var containsError = false
            if (Object.keys(answers).length === 0) {
                isNextStep = false
                setErrorMessage(<FormattedMessage id="apply_popup.errors.missing_answer" />)
                containsError = true
            }
            if (!props.jobInfo.motivation_letter && !containsError) {
                nextPageCounter = 0
                sendApplication()
            }

            executeNextStep(
                isNextStep,
                nextPageCounter,
                () => AnalyticsHelper.sendGTMEvent(
                    'Question From Employer Filled - Student Job',
                    AnalyticsHelper.constructStudentJobAnalyticsAttributes(
                        AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                        props.jobInfo,
                        getJobCompanyInfo(),
                        skipCVStep
                    )
                )
            )

            break
        case 3:
            if (motivationLetterContent === '' || motivationLetterContent === '<p><br></p>') {
                setErrorMessage(<FormattedMessage id="apply_popup.errors.missing_motivation_letter" />)
            } else {
                sendApplication()
                AnalyticsHelper.sendGTMEvent(
                    'Message To Employer Filled - Student Job',
                    AnalyticsHelper.constructStudentJobAnalyticsAttributes(
                        AnalyticsHelper.constructStudentUserObjectForApply(props.user, student, studentFormation, selectedDate),
                        props.jobInfo,
                        getJobCompanyInfo(),
                        skipCVStep
                    )
                )
            }

            break
        }
    }

    const executeNextStep = (isNextStep, nextPageCounter, analyticsStepEvent) => {
        if (isNextStep) {
            if (errorMessage !== '') {
                setErrorMessage('')
            }
            analyticsStepEvent()
            setActiveStep((prevActiveStep) => prevActiveStep + nextPageCounter)
        }
    }

    const handleCondensedJobApplication = (e) => {
        e.preventDefault()

        if (props.jobInfo.application_type === 'url') {
            setIsRedirectingToPartner(true)
            setTimeout(() => {
                updateProfileWithApplicationUrl()
                setIsRedirectingToPartner(false)
            }, 4000)
        } else {
            CandidacyAPI.CheckAndRetrieveStudentDocuments({
                document_id: documentIdToSend
            }).then((_) => {
                handleProfileUpdateAndSendApplication()
            }).catch((error) => {
                setErrorMessage(error.response.data.error)
                setImageArray(error.response.data.student_documents)
            })
        }
    }

    const handleBack = () => {
        var goBackByStepCount = 1

        if (activeStep === 3) {
            if (props.jobInfo.type === 'student_job' && props.jobInfo.job_questions && props.jobInfo.job_questions.length > 0) {
                goBackByStepCount = 1
            } else if (skipCVStep) {
                goBackByStepCount = 3
            } else {
                goBackByStepCount = 2
            }
        } else if (activeStep === 2 && skipCVStep) {
            goBackByStepCount = 2
        }
        setActiveStep((prevActiveStep) => prevActiveStep - goBackByStepCount)
    }

    const handleDateChange = (date) => {
        setSelectedDate(date)
    }

    const handleDateBlur = prop => (event) => {
        if (event.target.value !== '') {
            setStudent({ ...student, [prop + 'Error']: false, [prop + 'ErrorMessage']: '' })
        } else {
            setStudent({ ...student, [prop + 'Error']: true, [prop + 'ErrorMessage']: 'Champs requis' })
        }
    }

    const handleMultipleAnswersChange = (questionId, answerId) => event => {
        var answersIds
        if (questionId in answers) {
            answersIds = answers[questionId]
            if (answerId in answersIds) {
                delete answersIds[answerId]
            } else {
                answersIds[answerId] = true
            }
        } else {
            const newElem = {}
            newElem[answerId] = true
            answersIds = newElem
        }

        setAnswers({ ...answers, [questionId]: answersIds })
    }

    const checkCheckBoxSelection = (questionId, answerId) => {
        return (
            questionId in answers && answerId in answers[questionId]
        )
    }

    const handleMotivationLetterChange = (letterContent) => {
        setMotivationLetterContent(content => letterContent)
    }

    const getStepContent = (step) => {
        switch (step) {
        case 0:
            return renderProfileInfos()
        case 1:
            return renderCvInfos()
        case 2:
            return renderJobQuestions()
        case 3:
            return renderMotivationLetter()
        default:
            return 'Something happened'
        }
    }

    const renderProfileInfos = () => {
        return (
            <Container maxWidth='md' classes={{ root: classes.overrideRootPadding }}>
                <Grid container >
                    <Grid item xs={12} className={classes.mb3}>
                        <InfoProfile
                            student={student}
                            setStudent={setStudent}
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            handleDateBlur={handleDateBlur}
                            disabledSave
                            condensed
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mb3}>
                        <ExperienceProfile
                            setExperienceIsUpdated={setExperienceIsUpdated}
                            studentFormation={studentFormation}
                            setStudentFormation={(studentFormationData) => {
                                setStudent({ ...student, updated: true })
                                setStudentFormation(studentFormationData)
                            }}
                            disabledSave
                            disabledPaper
                            condensed
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mb3}>
                        <LanguageProfile
                            userLanguages={state.userLanguages}
                            studentLanguage={studentLanguage}
                            setStudentLanguage={(studentLanguageData) => {
                                setStudent({ ...student, updated: true })
                                setStudentLanguage(studentLanguageData)
                            }}
                            setStudent={(studentData) => (setStudent({ ...studentData, updated: true }))}
                            student={student}
                            blankLanguage={blankLanguage}
                            disabledSave
                        />
                    </Grid>
                    {props.jobInfo.company.ats_integration &&
                    <Grid item xs={12} className={classes.mb3}>
                        <Box className={classes.checkboxGDPR}>
                            <Checkbox
                                color='primary'
                                onClick={() => setGDPRChecked(!isGDPRChecked)}
                                icon={<IconFactory icon='checkbox-false' />}
                                checkedIcon={<IconFactory icon='checkbox-true'/>}
                                checked={isGDPRChecked}
                            />
                            <Typography className={classes.textGDPR}>
                                <FormattedMessage id="apply_popup.gdpr_checkbox" />
                            </Typography>
                        </Box>
                    </Grid>}
                </Grid>
            </Container>
        )
    }

    const renderCvInfos = () => {
        return (
            <Container maxWidth='md' classes={{ root: classes.overrideRootPadding }}>
                <UploadDocument
                    page='applyPopup'
                    profileId={state.profile.id}
                    imageArray={imageArray}
                    setImageArray={setImageArray}
                    documentIdToSend={documentIdToSend}
                    setDocumentIdToSend={setDocumentIdToSend}
                />
            </Container>
        )
    }

    const renderJobQuestions = () => {
        return (
            <Container maxWidth='md' classes={{ root: classes.overrideRootPadding }}>
                <Grid container spacing={3}>
                    {
                        props.jobInfo.job_questions.map((question, keyQuestion) => {
                            return (
                                <Grid item xs={12} key={question.id}>
                                    <Typography variant='h2' component={'div'} >
                                        <Box fontSize='16'>
                                            {question.name}
                                        </Box>
                                    </Typography>
                                    {
                                        question.answers.map((answer, keyAnswer) => {
                                            return (
                                                <Box key={answer.id} display='key' alignItems='center'>
                                                    <Checkbox
                                                        color='primary'
                                                        icon={<IconFactory icon='radio-false' className={classes.checkBox}/>}
                                                        checkedIcon={<IconFactory icon='radio-true' className={classes.checkBox} />}
                                                        checked={checkCheckBoxSelection(question.id, answer.id)}
                                                        onChange={handleMultipleAnswersChange(question.id, answer.id)}
                                                    />
                                                    {answer.name}
                                                </Box>
                                            )
                                        })
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        )
    }

    const renderMotivationLetter = () => {
        return (
            <Container maxWidth='lg' classes={{ root: classes.overrideRootPadding }}>
                {props.jobInfo.motivation_letter &&
                    <FormattedMessage id="apply_popup.apply_message_placeholder">
                        {
                            placeholder =>
                                <ReactQuill
                                    modules={{ clipboard: { matchVisual: false } }}
                                    value={motivationLetterContent}
                                    name='motivationLetterContent'
                                    onChange={handleMotivationLetterChange}
                                    placeholder={placeholder}
                                    className={classes.reactQuill}
                                />
                        }
                    </FormattedMessage>
                }
            </Container>
        )
    }

    const renderRegisterFunnel = () => {
        return (
            <Grid item xs={12} md={8} className={`${classes.formContainer} ${classes.loginContainer}`}>
                <Box>
                    <FormattedMessage id="apply_popup.login_text">
                        {translatedText =>
                            <Typography align='center' dangerouslySetInnerHTML={{ __html: translatedText }}/>
                        }
                    </FormattedMessage>
                </Box>
                <LoginRegisterPage
                    {...props.loginRegisterParams}
                    origin={JOB_APPLICATION}
                    jobInfo={props.jobInfo}
                    afterAuthAction={redirectionApply}
                    afterRegisterAction={redirectionRegisterApply}
                    withoutContainer
                />
            </Grid>
        )
    }

    const renderButtonBar = () => {
        return (
            <Grid item xs={12} className={classes.mb3}>
                <Box className={classes.buttonStepperContainer}>
                    {activeStep !== 0 &&
                        <Button
                            variant='secondary'
                            size='small'
                            onClick={handleBack}
                            className={`${classes.buttonStepper} ${classes.lowerCaseButton}`}
                            disabled={activeStep === 0}
                        >
                            <FormattedMessage id="apply_popup.previous_button" />
                        </Button>
                    }
                    <Button variant='cta-secondary' size='small' type='submit' className={classes.buttonStepper}>
                        {activeStep === showApplyButtonOnStep
                            ? <FormattedMessage id="apply_popup.apply_button" />
                            : <FormattedMessage id="apply_popup.next_button" />
                        }
                    </Button>
                </Box>
            </Grid>
        )
    }

    const renderStudentJobApplication = () => {
        return (
            <Grid item xs={12} md={8} className={classes.formContainer}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    color='primary'
                    classes={{ root: classes.stepperPaper }}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>
                                {<FormattedMessage id={label} />}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Grid container>
                    <form onSubmit={handleStudentJobApplication} style={{ width: '100%' }}>
                        <Grid item xs={12} className={classes.mb3}>
                            <Container maxWidth='md' classes={{ root: classes.overrideRootPadding }}>
                                <Typography variant={'h4'} component={'div'} color='primary'>
                                    <Box fontSize='20px'>
                                        <FormattedMessage
                                            id={titles[activeStep] ? titles[activeStep] : 'null'}
                                        />
                                    </Box>
                                </Typography>
                                <Typography variant='subtitle1' component='div' >
                                    {titleInfo[activeStep] && <FormattedMessage id={titleInfo[activeStep]} />}
                                </Typography>
                            </Container>
                        </Grid>
                        {activeStep === 3 &&
                            renderButtonBar()
                        }
                        <Grid item xs={12} className={classes.mb3}>
                            {getStepContent(activeStep)}
                        </Grid>
                        {
                            (errorMessage !== '') &&
                            <Grid item xs={12}>
                                <Typography variant='body2' className={classes.errorMessage}>
                                    <Box color='red'>
                                        {errorMessage}
                                    </Box>
                                </Typography>
                            </Grid>
                        }
                        {activeStep !== 3 &&
                            renderButtonBar()
                        }
                    </form>
                </Grid>
            </Grid>
        )
    }

    const renderCondensedJobApplication = () => {
        const isUrlApplication = props.jobInfo.application_type === 'url'
        const cvStep = 1
        return (
            <Grid item xs={12} md={8} className={classes.formContainer}>
                {isRedirectingToPartner
                    ? <Box className={classes.redirectingToPartnerContainer}>
                        <Typography variant='h2' component={'div'} className={classes.redirectingToPartnerTitle}>
                            <FormattedMessage id='apply_modal.redirecting_to_partner.title' />
                        </Typography>
                        <Typography variant='h3' component={'div'} className={classes.redirectingToPartnerSubTitle}>
                            <FormattedMessage id='apply_modal.redirecting_to_partner.subtitle' />
                        </Typography>
                        <Box className={classes.progressCircleBox}>
                            <ProgressCircle small disableMargin/>
                        </Box>
                    </Box>
                    : <form onSubmit={handleCondensedJobApplication} style={{ width: '100%' }}>
                        <Container maxWidth='md' className={classes.condensedJobContainer}>
                            <Grid container>
                                <Grid item xs={12} className={classes.mb3}>
                                    <InfoProfile
                                        student={student}
                                        setStudent={setStudent}
                                        selectedDate={selectedDate}
                                        handleDateChange={handleDateChange}
                                        handleDateBlur={handleDateBlur}
                                        disabledSave
                                        condensed
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.mb3}>
                                    <ExperienceProfile
                                        setExperienceIsUpdated={setExperienceIsUpdated}
                                        studentFormation={studentFormation}
                                        setStudentFormation={(studentFormationData) => {
                                            setStudent({ ...student, updated: true })
                                            setStudentFormation(studentFormationData)
                                        }}
                                        disabledSave
                                        condensed
                                    />
                                </Grid>
                                {!isUrlApplication &&
                                    <Grid item xs={12} className={classes.mb3}>
                                        <Typography variant={'h4'} component={'div'} color='primary'>
                                            <Box fontSize='20px'>
                                                <FormattedMessage id={titles[cvStep]} />
                                            </Box>
                                        </Typography>
                                        <Typography variant='subtitle1' component='div' >
                                            {titleInfo[cvStep] && <FormattedMessage id={titleInfo[cvStep]} />}
                                        </Typography>
                                        <UploadDocument
                                            page='applyPopup'
                                            profileId={state.profile.id}
                                            imageArray={imageArray}
                                            setImageArray={setImageArray}
                                            documentIdToSend={documentIdToSend}
                                            setDocumentIdToSend={setDocumentIdToSend}
                                        />
                                    </Grid>
                                }
                                {(errorMessage !== '') &&
                                    <Grid item xs={12}>
                                        <Typography variant='body2' className={classes.errorMessageCondensed}>
                                            <Box color='red'>
                                                {errorMessage}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                }
                                <Box className={classes.condensedSubmitContainer}>
                                    <Button variant='cta-secondary' size='small' type='submit'>
                                        {isUrlApplication
                                            ? <FormattedMessage id="apply_popup.apply_button_url_application" />
                                            : <FormattedMessage id="apply_popup.apply_button" />
                                        }
                                    </Button>
                                </Box>
                            </Grid>
                        </Container>
                    </form>
                }
            </Grid>
        )
    }

    const renderJobApplication = () => {
        if (props.jobInfo.type.match(/^(first_job|internship)$/g)) {
            return renderCondensedJobApplication()
        } else {
            return renderStudentJobApplication()
        }
    }

    return (
        <>
            <Dialog fullScreen open={true} PaperProps={{ className: classes.dialogContainer }} onClose={props.popupExit}>
                <Hidden mdUp>
                    <AppBar className={classes.appBar} position='static' color='transparent'>
                        <Toolbar variant='dense' className={classes.toolBar}>
                            <IconButton edge="start" color="inherit" onClick={props.popupExit} aria-label="close">
                                <IconFactory icon='close' />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Hidden>
                <Grid container className={classes.gridContainer}>
                    <Hidden smDown>
                        <Grid item md={4} className={classes.jobCardContainer}>
                            <Box style={{ width: '90%' }}>
                                <IconButton edge="start" color="inherit" onClick={props.popupExit} aria-label="close">
                                    <IconFactory icon='close' />
                                </IconButton>
                            </Box>
                            <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '10px 20px', width: '100%' }}>
                                <Typography variant={'h4'} component={'h5'} color='primary' className={classes.applyPopupTitle}>
                                    <FormattedMessage id="apply_popup.titles.title_job" />
                                </Typography>
                                <JobCardV2
                                    job={props.jobInfo}
                                    showJobType
                                    onCardClick={props.popupExit}
                                    key={props.jobInfo.id}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                    {
                        !isLogin &&
                            renderRegisterFunnel()
                    }
                    {
                        isLoaded
                            ? renderJobApplication()
                            : isLogin && <Box display='flex' justifyContent='center' alignItems='center' className={classes.circular}>
                                <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                            </Box>
                    }
                </Grid>
            </Dialog>
        </>
    )
}

export default ApplyPopupComponent
