import React from 'react'
import { useStyles } from '../../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'

const QuickFilterTag = (props) => {
    const classes = useStyles()
    const { page, quickFilterOption, quickFilterSelection, setFilters, filters } = props
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')

    const createRedirectionUrl = () => {
        const baseUrl = RouteMap.TrailingQuickFilterTag(page, quickFilterOption.value, quickFilterCollection[page])
        const params = window.location.search.replace(/quick_filter=[^&]*&?/, '')
            .replace(/\?$/, '')
            .replace(/[?&]$/, '')

        return `${baseUrl}${params}`
    }

    const redirectionUrl = createRedirectionUrl()

    const isSelected = quickFilterSelection === quickFilterOption.value

    const setQuickFilter = (selectedQuickFilter) => {
        if (selectedQuickFilter === filters.quickFilter) {
            setFilters({
                ...filters,
                quickFilter: ''
            })
        } else {
            setFilters({
                ...filters,
                quickFilter: selectedQuickFilter
            })
        }
    }

    return (<>
        {!isSelected &&
            <RouteMapLink
                className={`${classes.quickFilterOption} ${classes.notSelected}`}
                redirectionUrl={redirectionUrl}
                onClick={() => setQuickFilter(quickFilterOption.value)}
            >
                <IconFactory icon={quickFilterOption.icon} />
                <span className={classes.quickFilterText}>
                    <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
                </span>
                <Box className={`${classes.optionUnderline} ${classes.underlineInvisible}`} />
            </RouteMapLink>
        }
        {isSelected &&
            <RouteMapLink
                className={`${classes.quickFilterOption} ${classes.selected}`}
                redirectionUrl={redirectionUrl}
                onClick={() => setQuickFilter(quickFilterOption.value)}
            >
                <IconFactory icon={quickFilterOption.icon} />
                <span className={classes.quickFilterText}>
                    <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
                </span>
                <Box className={`${classes.optionUnderline} ${classes.underlineVisible}`} />
            </RouteMapLink>
        }
    </>)
}

export default QuickFilterTag
