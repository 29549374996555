import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { useStyles } from './AuthentificationContainer_style'
import { Card, CardContent, FormControl, Link, TextField, Typography, Box, Checkbox, useTheme } from '@material-ui/core'

import PasswordInput from 'inputs/PasswordInput/PasswordInput'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import IconFactory from 'icons/IconFactory/IconFactory'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import SessionAPI from 'api/SessionAPI'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import Button from 'shared/components/Button/Button'

const AuthentificationContainer = (props) => {
    const {
        handleChange,
        logInFacebook,
        logRequest,
        signUpRequest,
        state,
        setState,
        loginTitle = <FormattedMessage id='landing_login_page.login.title' />,
        loginSubTitle
    } = props
    const [isLogin, setIsLogin] = useState(true)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })
    const classes = useStyles()
    const theme = useTheme()
    const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID

    const reverseAuthPage = () => {
        setIsLogin(!isLogin)
    }

    const handleBlur = (event) => {
        SessionAPI.DoesUserEmailAlreadyExists({ email: event.target.value.toLowerCase() })
            .then((response) => {
                setUserVerifications(response.data.user_exist)
                setIsLogin(response.data.user_exist)
            })
    }

    const setUserVerifications = (userExist) => {
        if (isLogin && !userExist) {
            setFeedback({
                open: true,
                severity: 'info',
                message: <FormattedMessage id='landing_login_page.login_switch.no_account' />
            })
            setState({ ...state, signUpUserEmail: state.loginUserEmail })
        } else if (!isLogin && userExist) {
            setFeedback({
                open: true,
                severity: 'info',
                message: <FormattedMessage id='landing_login_page.login_switch.existing_account' />
            })
            setState({ ...state, loginUserEmail: state.signUpUserEmail })
        }
    }

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const renderRememberMe = () => {
        return (
            <Box className={classes.checkboxContainer}>
                <Checkbox
                    color='primary'
                    onClick={() => { setState({ ...state, rememberMe: !state.rememberMe }) }}
                    icon={<IconFactory icon='checkbox-false' />}
                    checkedIcon={<IconFactory icon='checkbox-true' />}
                    className={classes.checkbox}
                />
                <Typography className={classes.checkboxLabel}>
                    <FormattedMessage id='landing_login_page.remember_me' />
                </Typography>
            </Box>
        )
    }

    const renderLoginForm = () => {
        return (
            <form onSubmit={logRequest} className={classes.formContainer}>
                <Box className={classes.titleBox}>
                    <Typography component='h1' className={classes.formTitle}>
                        {loginTitle}
                    </Typography>
                    {loginSubTitle &&
                        <Typography>{loginSubTitle}</Typography>
                    }
                </Box>
                <Box className={classes.inputsContainer}>
                    <FormControl variant='outlined' className={classes.formControl}>
                        <Typography htmlFor='email' variant='h2' component='label' className={classes.inputLabel}>
                            <FormattedMessage id='landing_login_page.email' />
                        </Typography>
                        <TextField
                            id='email'
                            InputProps={{ classes: { root: classes.inputRoot }, disableUnderline: true }}
                            required
                            type='email'
                            value={state.loginUserEmail}
                            name='loginUserEmail'
                            onChange={handleChange}
                            autoComplete='on'
                            error={state.loginError.email}
                            helperText={state.loginError.email}
                            onBlur={handleBlur}
                        />
                    </FormControl>
                    <FormControl variant='outlined' className={classes.formControl}>
                        <Typography htmlFor='password' variant='h2' component='label' className={classes.inputLabel}>
                            <FormattedMessage id='landing_login_page.password' />
                        </Typography>
                        <PasswordInput
                            id='password'
                            value={state.loginUserPassword}
                            name='loginUserPassword'
                            onChange={handleChange}
                            error={state.loginError.password}
                            helperText={state.loginError.password}
                        />
                    </FormControl>
                </Box>
                {renderRememberMe()}
                <Button type='submit' className={classes.button}><FormattedMessage id='landing_login_page.login.button' /></Button>
                <Box className={classes.forgotPasswordLinkContainer}>
                    <RouteMapLink page='password/new' className={classes.forgotPasswordLink}>
                        <Typography component='div' className={classes.forgotPasswordLinkText}>
                            <FormattedMessage id='landing_login_page.forgot_password' />
                        </Typography>
                    </RouteMapLink>
                </Box>
            </form>
        )
    }

    const renderRegisterForm = () => {
        return (
            <form onSubmit={signUpRequest} className={classes.formContainer}>
                <Typography component='h1' className={classes.formTitle}>
                    <FormattedMessage id='landing_login_page.sign_up.title' />
                </Typography>
                <Box className={classes.inputsContainer}>
                    <FormControl variant='outlined' className={classes.formControl}>
                        <Typography htmlFor='email' variant='h2' component='label' className={classes.inputLabel}>
                            <FormattedMessage id='landing_login_page.email' />
                        </Typography>
                        <TextField
                            id='email'
                            InputProps={{ classes: { root: classes.inputRoot }, disableUnderline: true }}
                            required
                            type={'text'}
                            value={state.signUpUserEmail}
                            name='signUpUserEmail'
                            onChange={handleChange}
                            autoComplete='on'
                            error={state.signUpErrors?.email || false}
                            helperText={(state.signUpErrors?.email && state.signUpErrors?.email[0]) || ''}
                            onBlur={handleBlur}
                        />
                    </FormControl>
                    <FormControl variant='outlined' className={classes.formControl}>
                        <Typography htmlFor='password' variant='h2' component='label' className={classes.inputLabel}>
                            <FormattedMessage id='landing_login_page.new_password' />
                        </Typography>
                        <PasswordInput
                            id='password'
                            value={state.signUpUserPassword}
                            name='signUpUserPassword'
                            onChange={handleChange}
                            error={state.signUpErrors?.password || false}
                            helperText={(state.signUpErrors?.password && state.signUpErrors?.password[0]) || ''}
                        />
                    </FormControl>
                </Box>
                <Box className={classes.checkboxContainer}>
                    <Checkbox
                        color='primary'
                        onClick={() => { setState({ ...state, termsAccepted: !state.termsAccepted }) }}
                        icon={<IconFactory icon='checkbox-false' />}
                        checkedIcon={<IconFactory icon='checkbox-true' />}
                        className={classes.checkbox}
                    />
                    <Typography className={classes.checkboxLabel}>
                        <FormattedMessage
                            id='landing_login_page.sign_up.website_deal'
                            values={{
                                conditionsLink: <span style={{ textDecoration: 'underline' }}>
                                    <RouteMapLink page='terms' target='_blank' style={{ color: theme.palette.fg.purple }}>
                                        <FormattedMessage id='landing_login_page.sign_up.website_deal_link' />
                                    </RouteMapLink>
                                </span>
                            }}
                        />
                    </Typography>
                </Box>
                <Button type='submit' className={classes.button}><FormattedMessage id='landing_login_page.sign_up.button' /></Button>
            </form>)
    }

    const renderSocialLogins = () => {
        return (
            <FacebookLogin
                appId={FACEBOOK_APP_ID}
                fields='name,email,picture'
                callback={logInFacebook}
                onClick={() => AnalyticsHelper.sendGTMEvent('facebook-login-click')}
                disableMobileRedirect
                render={renderProps => (
                    <Button
                        variant='secondary'
                        className={classes.button}
                        onClick={renderProps.onClick}
                        startIcon={<Box className={classes.facebookIconContainer}>
                            <SVGFactory name='facebook' skipSvgWrapper width='12px' height='20px' />
                        </Box>}
                    >
                        <FormattedMessage id='landing_login_page.login.with_facebook' />
                    </Button>
                )}
            />
        )
    }

    return (
        <Card className={classes.authentificationCard}>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                closeFeedback={closeFeedback}
                severity={feedback.severity}
                anchor={{ vertical: 'top', horizontal: 'left' }}
            />
            <CardContent className={classes.authentificationCardContent}>
                {isLogin ? renderLoginForm() : renderRegisterForm()}
                <Box className={classes.reverseAuthLinkContainer} >
                    <Link
                        className={classes.reverseAuthLink}
                        onClick={reverseAuthPage}
                    >
                        <Typography className={classes.reverseAuthLinkText}>
                            {isLogin
                                ? <FormattedMessage id='landing_login_page.no_account' />
                                : <FormattedMessage id='landing_login_page.connect_with_account' />
                            }
                        </Typography>
                    </Link>
                </Box>
                <Typography component='div' className={classes.separationText}>
                    <FormattedMessage id='landing_login_page.login.socials_or'>
                        {socialsSeparationText => socialsSeparationText.toUpperCase()}
                    </FormattedMessage>
                </Typography>
                {renderSocialLogins()}
            </CardContent>
        </Card>
    )
}

export default AuthentificationContainer
