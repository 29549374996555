import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './CTAAlert_style'
import { Box, Typography } from '@material-ui/core'
import Button from 'shared/components/Button/Button.jsx'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { AlertCTAContext } from 'shared/contexts/AlertCTAContext'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import CTAJobAlert from './CTAJobAlert/CTAJobAlert'
import AlertStickyButton from './AlertStickyButton/AlertStickyButton'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'

const CTAAlert = (props) => {
    const { type, user, componentType, origin, className } = props

    const tmpCondition = () => {
        if (user && (user?.type !== 'student')) {
            return false
        }
        if (type === 'kot' && componentType !== 'showMoreButton') {
            return false
        }
        return true
        // TODO: Fixing Edouard changes by putting seemore in AlertCTA
    }
    if (user && (user?.type !== 'student')) return null
    if (type === 'kot') return null
    if (!tmpCondition()) return null
    const classes = useStyles()
    const context = useContext(LoginRegisterContext)
    const alertCTAContext = useContext(AlertCTAContext)

    const onClick = () => {
        if (JOB_TYPES_LIST.includes(type)) {
            if (!user) {
                context.openLoginRegisterModal({
                    origin: SAVE_FILTERS,
                    afterAuthAction: () =>
                        afterAuthAction(origin)
                })
            } else {
                alertCTAContext.renderAlertModal(origin)
                AnalyticsHelper.sendGTMEvent('alert-creation-click')
            }
        }
    }

    const afterAuthAction = (alertOrigin) => {
        URLParamsHandler.reloadPageAndAddParams({
            process: 'save_filters',
            alertOrigin
        })
    }

    return (<>
        {/* TODO: Fixing Edouard changes by putting seemore in AlertCTA */}
        <>
            {componentType === 'default' &&
                <Box className={`${classes.alertContainer} ${className}`}>
                    <Box className={classes.alertTextContainer}>
                        <Typography variant='h3' component='p' className={classes.alertText}>
                            <FormattedMessage id={`item_index.alert.${type}.title`} />
                        </Typography>
                    </Box>
                    <Button
                        variant='cta-primary'
                        size='small'
                        className={classes.alertButton}
                        onClick={onClick}
                    >
                        <FormattedMessage id={`item_offers.${type}.save_my_search_v2`} />
                    </Button>
                </Box>
            }
            {componentType === 'stickyButton' &&
                <AlertStickyButton stickyAlertBtn={onClick} type={type}/>
            }
            {componentType === 'banner' &&
                <CTAJobAlert handleReceiveJobs={onClick}/>
            }
        </>
    </>)
}

export default CTAAlert
