import React from 'react'

import { useStyles } from './StudentJobFiltersView_style'
import { Box, Hidden, Container } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler.js'
import StudentJobFiltersModal from 'modals/FilterModals/StudentJobFiltersModal/StudentJobFiltersModal'
import CityFilter from '../shared/CityFilter/CityFilter'
import TextFilter from '../shared/TextFilter/TextFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import StudyRelevantFilter from '../shared/StudyRelevantFilter/StudyRelevantFilter'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import StudentJobQuickFilters from './StudentJobQuickFilters'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'

const StudentJobFiltersView = (props) => {
    const {
        offerCount,
        debouncedFilterChange,
        handleFilterChange,
        filters,
        setFilters,
        reset,
        handleInputSearchCity,
        searchCities,
        setSearchCities,
        citiesInput,
        setCitiesInput,
        showStudentJobFiltersModal,
        setShowStudentJobFiltersModal,
        type,
    } = props
    const classes = useStyles()
    const studentJobSchedulesCollection = CollectionHandler.Get('studentJobSchedulesCollection')

    return (
        <>
            <FiltersViewWrapper titleId='item_offers.student_job.page_title_v2'>
                <StudentJobFiltersModal
                    showModal={showStudentJobFiltersModal}
                    setShowModal={setShowStudentJobFiltersModal}
                    debouncedFilterChange={debouncedFilterChange}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    offerCount={offerCount}
                    handleInputSearchCity={handleInputSearchCity}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    reset={reset}
                />
                <Box className={classes.firstFiltersRow}>
                    {/* Job filter */}
                    <Box className={classes.jobSearchFieldContainer}>
                        <TextFilter
                            debouncedFilterChange={debouncedFilterChange}
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            setFilters={setFilters}
                            filterTextId='search_bar.search.find_job'
                        />
                    </Box>
                    {/* City filter */}
                    <Hidden xsDown>
                        <Box className={classes.cityFieldContainer}>
                            <CityFilter
                                handleInputSearchCity={handleInputSearchCity}
                                searchCities={searchCities}
                                citiesInput={citiesInput}
                                setSearchCities={setSearchCities}
                                setCitiesInput={setCitiesInput}
                                setFilters={setFilters}
                                filters={filters}
                                width={350}
                            />
                        </Box>
                    </Hidden>
                    {/* Time filter  */}
                    <Hidden xsDown>
                        <Box className={classes.timeFieldContainer}>
                            <MultipleSelectFilter
                                type='jobSchedule'
                                collection={studentJobSchedulesCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='job_filters.schedule.timetable'
                            />
                        </Box>
                    </Hidden>
                    {/* More filters button */}
                    <Box className={classes.moreFiltersButtonContainer}>
                        <MoreFilters setShowModal={setShowStudentJobFiltersModal} filters={filters} />
                    </Box>
                    {/* Reset filters button */}
                    <Hidden smDown>
                        <Box className={classes.resetFiltersButtonContainer}>
                            <ResetFilters reset={reset} />
                        </Box>
                    </Hidden>
                </Box>
                <Box className={classes.secondFiltersRow}>
                    <Hidden smDown>
                        <StudyRelevantFilter typeOfJob={type} filters={filters} setFilters={setFilters} />
                    </Hidden>
                    <Hidden mdUp>
                        <CityFilter
                            handleInputSearchCity={handleInputSearchCity}
                            searchCities={searchCities}
                            citiesInput={citiesInput}
                            setSearchCities={setSearchCities}
                            setCitiesInput={setCitiesInput}
                            setFilters={setFilters}
                            filters={filters}
                            width={350}
                        />
                    </Hidden>
                </Box>
            </FiltersViewWrapper>
            <Container maxWidth='lg'>
                <StudentJobQuickFilters filters={filters} setFilters={setFilters}/>
            </Container>
        </>
    )
}

export default StudentJobFiltersView
