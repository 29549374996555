import React, { useState, useEffect, useRef } from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'
import isEqual from 'lodash.isequal'
import { Container } from '@material-ui/core'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CTABlock from './CTABlock/CTABlock'
import FeedbackFactory from 'components/FeedbackFactory/FeedbackFactory'
import GenericHelper from 'assets/GenericHelper'
import ItemIndex from './ItemIndex/ItemIndex'
import ItemOffers from './ItemOffers/ItemOffers'
import JobIndexHelmet from 'helmets/JobIndexHelmet'
import MapProvider from 'wrappers/MapProvider/MapProvider'
import RouteMap from 'assets/RouteMap.js'
import Searchbar from 'components/Searchbar/Searchbar'
import JobAlertModal from 'modals/JobAlertModal/JobAlertModal'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import { ITEM_WITH_ANALYTICS_FILTER_LIST } from 'assets/ItemHelper'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'
import FiltersHelper from 'assets/FiltersHelper'
import CTABannerPostJobs from 'shared/components/CTABannerPostJobs/CTABannerPostJobs.jsx'
import CTABannerHiring from 'shared/components/CTABannerHiring/CTABannerHiring.jsx'
import CTABannerCreateProfile from 'shared/components/CTABannerCreateProfile/CTABannerCreateProfile.jsx'

const ItemIndexMain = (props) => {
    const mapRef = useRef(null)
    const feedbackRef = useRef(null)

    const [state, setState] = useState({
        applyJob: false,
        cardRef: useRef(),
        currentPage: URLParamsHandler.loadCurrentPage(),
        filters: null,
        indexRef: useRef(),
        isCheckMap: true,
        itemDivSelected: '',
        itemSelected: '',
        items: [],
        itemsGeocoding: false,
        jumpingRef: useRef(),
        listRef: useRef(),
        loadMoreStatus: true,
        loadPreviousPage: URLParamsHandler.loadPreviousPageBool(),
        loading: true,
        loadingMore: false,
        needRedirect: false,
        offerCount: '0',
        redirect_to: '',
        showAlertModalOnPageLoad: props.user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === SAVE_FILTERS,
        showAlertModalOnPageLoadOrigin: URLParamsHandler.parseURLParams().alertOrigin,
        showRef: useRef()
    })

    const urlFilters = URLParamsHandler.loadUrlFilters(props.type)

    useEffect(() => {
        sendIndexViewEvent()
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }, [])

    const getIndexListViewEventName = () => {
        switch (props.type) {
        case 'kot':
            return 'Kot List Viewed'
        case 'company':
            return 'Company List Viewed'
        default:
            return 'Job List Viewed'
        }
    }

    const sendIndexViewEvent = () => {
        AnalyticsHelper.sendGTMEvent(
            getIndexListViewEventName(),
            AnalyticsHelper.constructIndexListViewedAnalyticsAttributes(
                props.user,
                props.type
            )
        )
    }

    const sendFilterEvent = (filters) => {
        AnalyticsHelper.sendGTMEvent(
            'Search Filter Applied',
            AnalyticsHelper.constructSearchFilterAppliedAnalyticsAttributes(
                props.user,
                filters
            )
        )
    }

    const setFilters = (filters) => {
        setState((prevState) => ({ ...prevState, filters }))
    }

    const setCheckMap = (checked) => {
        setState((prevState) => ({ ...prevState, isCheckMap: checked }))
    }

    const getCoords = (filterCities) => {
        if (filterCities && filterCities.length) {
            return filterCities.map((option) => {
                return {
                    long: option.long,
                    lat: option.lat,
                    value: option.value
                }
            })
        } else {
            return 'null'
        }
    }

    const isSearchEmpty = () => {
        if (!ITEM_WITH_ANALYTICS_FILTER_LIST.includes(props.type)) { return true }

        if (state.filters) {
            return (
                !state.filters.studyDomain?.length &&
                !state.filters.location?.length &&
                !state.filters.companySize?.length &&
                !state.filters.jobSchedule?.length &&
                !state.filters.sector?.length &&
                !state.filters.jobType?.length &&
                !state.filters.title?.length &&
                !state.filters.quickFilter?.length &&
                !state.filters.traineeship?.length &&
                !state.filters.jobSchedule?.length
            )
        }
        return false
    }

    const filterItems = (tmpFilters) => {
        const loadingMore = !tmpFilters
        const filters = tmpFilters || state.filters
        const haveFiltersChanged = !isEqual(tmpFilters, state.filters)

        const currentPage =
            loadingMore && !state.loadPreviousPage
                ? state.currentPage + 1
                : state.loadPreviousPage
                    ? state.currentPage
                    : 1

        setState((prevState) => ({
            ...prevState,
            filters,
            currentPage,
            loadingMore,
            loading: !loadingMore
        }))

        if (!isSearchEmpty()) sendFilterEvent(filters)

        const params = {
            locale: RouteMap.GetLocaleFromUrl(),
            title: filters.title,
            study_domain: FiltersHelper.getValues(filters.studyDomain),
            study_type: FiltersHelper.getValues(filters.studyType),
            location: getCoords(filters.location),
            company_size: FiltersHelper.getValues(filters.companySize),
            sector: FiltersHelper.getValues(filters.sector),
            quick_filter: filters.quickFilter,
            kot_type: FiltersHelper.getValues(filters.kotType),
            job_type: FiltersHelper.getValues(filters.jobType),
            job_schedule: FiltersHelper.getValues(filters.jobSchedule),
            availability: FiltersHelper.getValues(filters.disponibility),
            rent: FiltersHelper.getValues(filters.price),
            available_rooms: FiltersHelper.getValues(filters.roomCount),
            selectedTags: filters.selectedTags,
            filterText: filters.filterText,
            traineeship: filters.traineeship,
            paid: filters.paid,
            page: currentPage // TODO: Fixing Edouard changes by putting seemore in AlertCTA, filter would reload the same filter the first 2 time
        }

        axios.get(props.filterUrl, { params })
            .then((response) => {
                let loadMoreStatus = true

                if (props.type === 'company' && response.data.res) {
                    if (response.data.res.length < 18) {
                        loadMoreStatus = false
                    }
                } else {
                    if (response.data.res.length < 27) {
                        loadMoreStatus = false
                    }
                }

                if (response.data.search_count === response.data.res.length) {
                    loadMoreStatus = false
                }

                let items = loadingMore ? state.items : []
                items = items.concat(response.data.res)

                if (response.data.search_count === items.length) {
                    loadMoreStatus = false
                }

                if (props.user) {
                    const hasFilters = Object.values(filters).some((filter) =>
                        GenericHelper.isPresent(filter)
                    )
                    if (hasFilters && haveFiltersChanged) {
                        AnalyticsHelper.sendAnalyticsEvent('track', {
                            user_id: props.user.id,
                            event_name: 'Item Searched',
                            custom_props: {
                                item_type: props.type,
                                filters: params,
                                results_count: response.data.search_count
                            }
                        })
                    }
                }

                setState((prevState) => ({
                    ...prevState,
                    loadMoreStatus,
                    items,
                    itemsGeocoding: response.data.items_geocoded,
                    offerCount: response.data.search_count,
                    loading: false,
                    loadingMore: false
                }))

                window.prerenderReady = true

                if (state.loadPreviousPage) {
                    setState((prevState) => ({
                        ...prevState,
                        currentPage
                    }))
                }

                URLParamsHandler.createURLFilters(props.type, filters, currentPage)
                props.refreshParamsForUrlTranslation()
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const renderCTABanner = () => {
        if (props.type === 'company') {
            return (
                <CTABannerHiring
                    userType={props.user?.type}
                    type={props.type}
                />
            )
        } else if (props.type === 'kot') {
            return (
                <CTABannerCreateProfile
                    userType={props.user?.type}
                    type={props.type}
                />
            )
        } else {
            return (
                <CTABannerPostJobs
                    userType={props.user?.type}
                    type={props.type}
                />
            )
        }
    }

    return (
        <>
            <JobIndexHelmet
                jobType={props.type}
                selectedLocationTag={props.selectedLocationTag}
                resultsCount={state.offerCount}
                lang={props.lang}
                altLangUrls={props.altLangUrls}
                pathname={props.pathname}
            />
            {props.user &&
                <JobAlertModal
                    filters={state.filters}
                    showAlertModalOnPageLoad={state.showAlertModalOnPageLoad}
                    showAlertModalOnPageLoadOrigin={state.showAlertModalOnPageLoadOrigin}
                    jobType={props.type}
                    user={props.user}
                />
            }
            <FeedbackFactory ref={feedbackRef} />
            <div className="item-index-page-container">
                <div className="item-index-main-container">
                    <div className="item-index-main" ref={state.indexRef}>
                        <Searchbar
                            key="searchbar"
                            type={props.type}
                            selectedLocationTag={props.selectedLocationTag}
                            user={props.user}
                            handleFilterChange={filterItems}
                            filters={state.filters || {}}
                            setFilters={setFilters}
                            location={props.selectedLocationTag}
                            urlFilters={urlFilters}
                            offerCount={state.offerCount}
                        />
                        <Container maxWidth="lg" className="container">
                            {!state.loading && (
                                <ItemOffers
                                    offerCount={state.offerCount}
                                    cardRef={state.cardRef}
                                    listRef={state.listRef}
                                    user={props.user}
                                    type={props.type}
                                    setCheckMap={setCheckMap}
                                    isCheckMap={state.isCheckMap}
                                    selectedLocationTag={props.selectedLocationTag}
                                />
                            )}
                            {props.type === 'kot' && (
                                <MapProvider itemsGeocoding={state.itemsGeocoding} isCheckMap={state.isCheckMap}>
                                    <ItemIndex
                                        type={props.type}
                                        currentTab={props.currentTab}
                                        items={state.items}
                                        itemsGeocoding={state.itemsGeocoding}
                                        cardSlider={props.cardSlider}
                                        cardRef={state.cardRef}
                                        listRef={state.listRef}
                                        mapRef={mapRef}
                                        userAddress={props.userAddress}
                                        userType={props.user && props.user.type}
                                        currentPage={state.currentPage}
                                        offerCount={state.offerCount}
                                        loadMoreStatus={state.loadMoreStatus}
                                        pageName={props.pageName}
                                        ads={props.ads}
                                        selectedArticletag={props.selectedArticleTag}
                                        selectedLocationTag={props.selectedLocationTag}
                                        isCheckMap={state.isCheckMap}
                                        jumpingRef={state.jumpingRef}
                                        lang={props.lang}
                                        loading={state.loading}
                                        loadingMore={state.loadingMore}
                                        filterItems={filterItems}
                                    />
                                </MapProvider>
                            )}
                            {props.type !== 'kot' && (
                                <ItemIndex
                                    type={props.type}
                                    currentTab={props.currentTab}
                                    items={state.items}
                                    itemsGeocoding={state.itemsGeocoding}
                                    cardSlider={props.cardSlider}
                                    cardRef={state.cardRef}
                                    listRef={state.listRef}
                                    mapRef={mapRef}
                                    userAddress={props.userAddress}
                                    user={props.user}
                                    userType={props.user && props.user.type}
                                    currentPage={state.currentPage}
                                    offerCount={state.offerCount}
                                    filters={state.filters}
                                    loadMoreStatus={state.loadMoreStatus}
                                    pageName={props.pageName}
                                    ads={props.ads}
                                    selectedArticletag={props.selectedArticleTag}
                                    jumpingRef={state.jumpingRef}
                                    lang={props.lang}
                                    loading={state.loading}
                                    loadingMore={state.loadingMore}
                                    filterItems={filterItems}
                                    selectedLocationTag={props.selectedLocationTag}
                                />
                            )}
                        </Container>
                        {props.selectedLocationTag &&
                            props.type !== 'company' &&
                            !state.loadMoreStatus && (
                            <CTABlock
                                page={props.page}
                                type={props.type}
                                selectedLocationTag={props.selectedLocationTag}
                                loadMoreStatus={state.loadMoreStatus}
                            />
                        )}
                    </div>
                    <br />
                    {renderCTABanner()}
                </div>
            </div>
        </>
    )
}

export default ItemIndexMain
