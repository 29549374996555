import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './ShowMoreButton_style'
import { Box } from '@material-ui/core'
import Button from 'shared/components/Button/Button.jsx'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { AlertCTAContext } from 'shared/contexts/AlertCTAContext'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'

const ShowMoreButton = (props) => {
    const { user, type, origin, showMoreAction, loadingMore, loadMoreStatus } = props
    if (!loadMoreStatus || loadingMore) return null

    const classes = useStyles()
    const loginRegisterContext = useContext(LoginRegisterContext)
    const alertCTAContext = useContext(AlertCTAContext)

    const openAlertModal = () => {
        alertCTAContext.renderAlertModal(origin)
        AnalyticsHelper.sendGTMEvent('alert-creation-click')
    }

    const afterAuthAction = (alertOrigin) => {
        URLParamsHandler.reloadPageAndAddParams({
            process: 'save_filters',
            alertOrigin
        })
    }

    const openRegisterModal = (alertOrigin) => {
        loginRegisterContext.openLoginRegisterModal({
            origin: SAVE_FILTERS,
            loginTitle: <FormattedMessage id='show_more_button.cta_alert.login_title' />,
            loginSubTitle: <FormattedMessage id={`item_index.alert.${type}.title`} />,
            afterAuthAction: () =>
                afterAuthAction(origin)
        })
    }

    const onClick = () => {
        if (JOB_TYPES_LIST.includes(type)) {
            if (!user) {
                openRegisterModal()
            } else {
                openAlertModal()
            }
        }
    }

    const showMoreOnClick = () => {
        if (!alertCTAContext.alertModalShown) {
            onClick()
        }
        showMoreAction && showMoreAction()
    }

    return (
        <Box className={classes.showMoreButtonBox}>
            <Button size='small' onClick={showMoreOnClick} className={classes.showMoreButton}>
                <FormattedMessage id='item_index.show_more_button' />
            </Button>
        </Box>
    )
}

export default ShowMoreButton
