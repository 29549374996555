import React from 'react'

import { useStyles } from './FirstJobFiltersView_style'
import { Box, Hidden, Container } from '@material-ui/core'

import CityFilter from '../shared/CityFilter/CityFilter'
import TextFilter from '../shared/TextFilter/TextFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import FirstJobFiltersModal from 'modals/FilterModals/FirstJobFiltersModal/FirstJobFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import FirstJobQuickFilters from './FirstJobQuickFilters'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'

const FirstJobFiltersView = (props) => {
    const { offerCount, debouncedFilterChange, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showFirstJobFiltersModal, setShowFirstJobFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')

    return (
        <>
            <FiltersViewWrapper titleId='item_offers.first_job.page_title_v2'>
                <FirstJobFiltersModal
                    showModal={showFirstJobFiltersModal}
                    setShowModal={setShowFirstJobFiltersModal}
                    debouncedFilterChange={debouncedFilterChange}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    offerCount={offerCount}
                    handleInputSearchCity={handleInputSearchCity}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    reset={reset}
                />
                <Box className={classes.firstFiltersRow}>
                    {/* internship filter */}
                    <Box className={classes.firstJobSearchFieldContainer}>
                        <TextFilter
                            debouncedFilterChange={debouncedFilterChange}
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            setFilters={setFilters}
                            filterTextId='search_bar.search.job'
                        />
                    </Box>
                    {/* Study domain filter */}
                    <Hidden xsDown>
                        <Box className={classes.studyDomainField}>
                            <MultipleSelectFilter
                                type='studyDomain'
                                collection={studyDomainCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={350}
                                filterTextId='search_bar.study.field_singular'
                            />
                        </Box>
                    </Hidden>
                    {/* City filter */}
                    <Hidden xsDown>
                        <Box className={classes.cityFieldContainer}>
                            <CityFilter
                                handleInputSearchCity={handleInputSearchCity}
                                searchCities={searchCities}
                                citiesInput={citiesInput}
                                setSearchCities={setSearchCities}
                                setCitiesInput={setCitiesInput}
                                setFilters={setFilters}
                                filters={filters}
                                width={350}
                            />
                        </Box>
                    </Hidden>
                    {/* Company size filter  */}
                    <Hidden smDown>
                        <Box className={classes.companySizeFieldContainer}>
                            <MultipleSelectFilter
                                type='companySize'
                                collection={companySizeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.company_size'
                            />
                        </Box>
                    </Hidden>
                    {/* Sector filter  */}
                    <Hidden mdDown>
                        <Box className={classes.sectorFieldContainer}>
                            <MultipleSelectFilter
                                type='sector'
                                collection={sectorCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={500}
                                filterTextId='search_bar.sector'
                            />
                        </Box>
                    </Hidden>
                    {/* More filters button */}
                    <Box className={classes.moreFiltersButtonContainer}>
                        <MoreFilters
                            setShowModal={setShowFirstJobFiltersModal} filters={filters}
                        />
                    </Box>
                    {/* Reset filters button */}
                    <Hidden smDown>
                        <Box className={classes.resetFiltersButtonContainer}>
                            <ResetFilters reset={reset} />
                        </Box>
                    </Hidden>
                </Box>
            </FiltersViewWrapper>
            <Container maxWidth='lg'>
                <FirstJobQuickFilters filters={filters} setFilters={setFilters}/>
            </Container>
        </>
    )
}

export default FirstJobFiltersView
